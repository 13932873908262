import React from 'react';
import Layout from '../../components/layout';
import TitleHeader from '../../components/TitleHeader';
import BlogPost from '../../components/BlogPost';
// import All from '../../images/uslugi/medEstet.png';
import Przebarwiony from '../../images/blog/przebarwiony_zab.webp'


const Impact = (props) => {
	return (
		<Layout {...props}>
			<TitleHeader title="Przebarwiony ząb po leczeniu kanałowym – czy można coś z tym zrobić?" />
            <BlogPost post={{img: Przebarwiony, text: article.text}}/>
		</Layout>
	);
};
const article = {
	text: `
    Masz ząb lub zęby które są przebarwione, różnią się kolorem od reszty (są brunatno-szare)  i być może były w przeszłości leczone kanałowo? Może taki ząb to górna jedynka lub dwójka i żadne zdjęcie nie może się udać bez wyraźnie widocznej różnicy w uśmiechu?
    Przebarwienie pojedynczego zęba najczęściej powstaje po leczeniu kanałowym wykonanym z powodu urazu lub dużego ubytku próchniczego. Powodem przebarwienia może być nieszczelna plomba, materiał wypełniający kanał starszego typu lub wcześniejszy uraz.
    Co najważniejsze, w zdecydowanej większości wypadków przebarwiony ząb można przywrócić do właściwej kondycji estetycznej. Koniec ze staniem w drugim rzędzie na zdjęciach ze znajomymi! 
    Kluczem do sukcesu jest konsultacja stomatologiczna i zdiagnozowanie przyczyny przebarwienia. Możliwe, że przyczyną przebarwienia jest tylko nieszczelna plomba, a jej wymiana rozwiąże problem. Jeśli powodem przebarwienia jest materiał  starego typu wypełniający kanał korzeniowy, trzeba go najpierw wyciągnąć, powtórnie przeleczyć ząb kanałowo, a do środka zaaplikować środek wybielający. Ząb będzie powoli wracał do swojego pierwotnego koloru. Co około tydzień konieczne są wymiany materiału wybielającego w zębie. Zwykle przeprowadza się około trzy takie serie. Na koniec całego procesu pozostaje tylko właściwa odbudowa zęba. Zatem w niecały miesiąc możesz z powrotem cieszyć się pięknym kolorem wszystkich swoich zębów.
    `
}

export default Impact;
